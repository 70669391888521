<template>
  <div class="scheme">
    <BaseDialog
      title="血管通路"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="800px"
      height="auto"
    >
      <div class="schemeBox mar20">
        <div class="userItem">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="Rule.ASSESSIMG"
            label-width="104px"
            label-position="right"
            :inline="true"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="拍照日期：" prop="upload_time">
              <el-date-picker
                v-model="ruleForm.upload_time"
                value-format="YYYY-MM-DD HH:mm:ss"
                type="datetime"
                placeholder="请选择拍照日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="操作医生：" prop="upload_doctor_id">
              <el-select
                v-model="ruleForm.upload_doctor_id"
                placeholder="请选择操作医生"
              >
                <el-option
                  v-for="item in userNameList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="addr" label="备注：">
              <el-input
                v-model="ruleForm.remark"
                maxlength="255"
                :rows="5"
                placeholder="请输入备注"
                show-word-limit
                type="textarea"
              />
            </el-form-item>
            <el-form-item class="addr" label="通路图片：">
              <el-upload
                action=""
                ref="uploadimg"
                :class="{ hide: hideUpload }"
                :http-request="handleRequest"
                list-type="picture-card"
                :on-change="handleEditChange"
                v-model:file-list="imgList"
                :limit="8"
              >
                <template #default>
                  <i class="fa fa-plus"></i>
                </template>
                <template #file="{ file }">
                  <div style="height: 100%">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handleRemove(file)"
                      >
                        <i class="fa fa-trash-o"></i>
                      </span>
                      <!-- <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="preview(file)"
                      >
                        <i class="fa fa-search-plus"></i>
                      </span> -->
                    </span>
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveAccessImg"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs, nextTick } from 'vue'
import Rule from '@/utils/rule'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import comm from '@/utils/comm'
let port = location.protocol
        let ip = comm.cesuUrl
        let requestUrl = `${port}//${ip}/`
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dataForm: {},
      ruleForm: {
        photo: [],
      },
      imgList: [],
      visible: false,
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
      Rule: Rule,
      uploadimg: null,
      hideUpload: false,
      saveLoad: false,
      ruleFormRef: false,
    })

    const initData = (item) => {
      state.visible = true
      state.ruleForm = {}
      state.ruleForm.photo = []
      state.imgList = []
      if (item && item.id) {
        state.ruleForm = item
        if (state.ruleForm.photo && state.ruleForm.photo.length > 0) {
          state.ruleForm.photo.map((item) => {
            item.uid = item.url
          })
        } else {
          state.ruleForm.photo = []
        }
        state.imgList = state.ruleForm.photo
      }
      state.hideUpload = state.imgList.length >= 8
    }

    // 文件上传
    const handleRequest = async (param) => {
      let fileObj = param.file
      let form = new FormData()
      form.append('img_file', fileObj)
      form.append('type', 'tonglu_photo')
      state.uploadimg.handleRemove(fileObj)
      let res = await service.post('/api/common/upload_img', form)
      if (res.code === 0) {
        let row = {
          type: 1,
          uid: fileObj.uid,
          url:requestUrl + res.data.url,
        }
        state.imgList.push(row)
      }
    }

    const handleRemove = (file) => {
      ElMessageBox.confirm('您确定要删除此张图片吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          state.uploadimg.handleRemove(file)
          nextTick(() => {
            state.hideUpload = state.imgList.length >= 8
          })
          for (let i = 0; i < state.imgList.length; i++) {
            if (state.imgList[i].uid === file.uid) {
              state.imgList.splice(i, 1)
              return
            }
          }
        })
        .catch(() => {})
    }
    const handleEditChange = () => {
      state.hideUpload = state.imgList.length >= 8
    }

    const route = useRoute()
    const saveAccessImg = () => {
      state.ruleFormRef.validate(async (valid) => {
        if (valid) {
          console.log(state.ruleForm)
          state.ruleForm.photo = state.imgList
          if (state.ruleForm.photo.length <= 0) {
            ElMessage.error('请上传通路图片')
            return
          }
          let url = ''
          state.ruleForm.patient_id = route.params.id
          if (state.ruleForm.id) {
            state.ruleForm.tonglu_photo_id = state.ruleForm.id
            url = '/api/patient_tonglu/edit_tonglu_photo'
          } else {
            url = '/api/patient_tonglu/create_tonglu_photo'
          }
          let res = await service.post(url, state.ruleForm)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('refreshDataList')
          }
        }
      })
    }

    return {
      ...toRefs(state),
      initData,
      handleRequest,
      handleRemove,
      handleEditChange,
      saveAccessImg,
    }
  },
}
</script>

<style scoped lang="scss">
.templateBut {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 10px;
}
.schemeBox {
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
  }
  &:deep(.hide .el-upload) {
    display: none;
  }
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 45%;
    margin-right: 0;
    &.addr {
      width: 100%;
    }
  }
  .el-form-item:first-child {
    margin-right: 10%;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
